const constant = {};

const env = "dev";

if (env === "local") {
    constant.baseUrl = "http://localhost:8081/api/v1";
} else if(env === "dev") {
    constant.baseUrl = "https://dev.api.marketi3d.k-gouzien.fr/api/v1";
} else if (env === "prod") {
    constant.baseUrl = "https://api.marketi3d.k-gouzien.fr/api/v1";
}


export default constant;